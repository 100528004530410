import { IconType } from "react-icons";
import {
  BsCalendarHeart,
  BsCodeSquare,
  BsCoin,
  BsHeart,
  BsImage,
} from "react-icons/bs";
import { BsChatLeftHeart } from "react-icons/bs";
import { BsType } from "react-icons/bs";
import { BsWindowDesktop } from "react-icons/bs";
import { RiUserHeartLine } from "react-icons/ri";
import { BsBox2Heart } from "react-icons/bs";
import { AiOutlineDashboard, AiOutlineTeam } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { BiMessageAltDetail } from "react-icons/bi";
import { MdOutlineCampaign, MdOutlineInstallDesktop } from "react-icons/md";

export interface Route {
  name: string;
  icon: IconType;
  path: string;
  hidden: boolean;
  children?: Route[];
  adminOnly?: boolean;
}

interface RouteGroup {
  label: string;
  routes: Route[];
}

export const appRoutes: RouteGroup[] = [
  {
    label: "Fundraising",
    routes: [
      {
        name: "Dashboard",
        icon: AiOutlineDashboard,
        path: "/",
        hidden: false,
      },
      {
        name: "Donations",
        icon: BsBox2Heart,
        path: "/donations",
        hidden: false,
      },
      {
        name: "Regular Plans",
        icon: BsCalendarHeart,
        path: "/recurring",
        hidden: false,
      },

      {
        name: "Donors",
        icon: RiUserHeartLine,
        path: "/donors",
        hidden: false,

        children: [
          {
            name: "Donor",
            icon: RiUserHeartLine,
            path: "/donors/[donorId]",
            hidden: true,
          },
        ],
      },
      {
        name: "Campaigns",
        icon: MdOutlineCampaign,
        path: "/campaigns",
        hidden: false,
        children: [
          {
            name: "Campaign",
            icon: RiUserHeartLine,
            path: "/campaigns/[campaignId]",
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    label: "Donation Checkout",
    routes: [
      {
        name: "Branding",
        icon: BsImage,
        path: "/branding",
        hidden: false,
      },
      {
        name: "Messaging",
        icon: BiMessageAltDetail,
        path: "/messaging",
        hidden: false,
      },
      {
        name: "Settings",
        icon: BsWindowDesktop,
        path: "/settings",
        hidden: false,
      },
      {
        name: "Installation",
        icon: MdOutlineInstallDesktop,
        path: "/installation",
        hidden: false,
      },
    ],
  },
  {
    label: "Settings",
    routes: [
      {
        name: "Integrations",
        icon: FiSettings,
        path: "/integrations",
        hidden: false,
        adminOnly: true,
      },
      {
        name: "Team",
        icon: AiOutlineTeam,
        path: "/team",
        hidden: false,
        adminOnly: true,
      },
    ],
  },
];

export function findRouteByPath(path: string): Route | null {
  function traverseRoutes(route: Route): Route | null {
    if (route.path === path) {
      return route;
    }

    if (route.children) {
      for (const child of route.children) {
        const foundRoute = traverseRoutes(child);
        if (foundRoute) {
          return foundRoute;
        }
      }
    }

    return null;
  }

  for (const route of appRoutes) {
    for (const subRoute of route.routes) {
      const foundRoute = traverseRoutes(subRoute);
      if (foundRoute) {
        return foundRoute;
      }
    }
  }

  return null;
}
